import React, { useState, useCallback, useMemo, useRef } from 'react';
import { Grid, Typography, Tooltip, Box } from '@material-ui/core';
import { Button, Dropdown } from '@clef/client-library';
import {
  useDataBrowserState,
  getSelectedMediaCount,
  formatStateToSelectMediaOption,
} from '../dataBrowserState';
import {
  Metadata,
  MetadataValueType,
  MetadataType,
  LabelType,
  UserPermission,
} from '@clef/shared/types';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { useAtom } from 'jotai';

import RemoveMediaDialog from './RemoveMediaDialog';
import SetMetadataDropdown from '../../../components/SetMetadata/SetMetadataDropdown';
import LabelingTaskCreationDialog from '../../../components/LabelingTaskCreationDialog/LabelingTaskCreationDialog';
import { useSnackbar } from 'notistack';
import { attachMetadataToMediasApi, useMetadataApi } from '../../../hooks/api/useMetadataApi';
import { useGetProjectSplitQuery, useGetSelectedProjectQuery } from '@/serverStore/projects';
import useStyles from './styles';
import { useHasPermission } from '../../../hooks/useProjectRolePermissions';
import SetSplitDropdown from '../../../components/SetSplit/SetSplitDropdown';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import CLEF_PATH from '../../../constants/path';
import { Link } from 'react-router-dom';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import classNames from 'classnames';
import { useUpdateMediaSplitMutation } from '@/serverStore/dataset';
import {
  createSnapshotDialogModeAtom,
  CreateSnapshotDialogMode,
} from '@/uiStates/datasetSnapshot/pageUIStates';
import { AddTagDropdown } from '@/components/AddTagDropdown';

interface MediaSelectionActionsProps {
  style?: React.CSSProperties;
  onlyRemove?: boolean;
}

const MediaSelectionActions: React.FC<MediaSelectionActionsProps> = ({
  style,
  onlyRemove = false,
}) => {
  const styles = useStyles();
  const { state, dispatch } = useDataBrowserState();
  const { enqueueSnackbar } = useSnackbar();
  const { data: project } = useGetSelectedProjectQuery();
  const selectedMediaCount = useMemo(() => getSelectedMediaCount(state), [state]);
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const selectedMediaOptions = useMemo(
    () => formatStateToSelectMediaOption(state, currentModelId),
    [state, currentModelId],
  );

  const [showRemoveMediaDialog, setShowRemoveMediaDialog] = useState(false);
  const [showLabelingTaskDialog, setShowLabelingTaskDialog] = useState(false);
  const [metadataMapping] = useMetadataApi(project?.id);
  const metadataList = Object.values(metadataMapping || {});
  const optionsBtnRef = useRef<HTMLDivElement | null>(null);
  const updateMediaSplit = useUpdateMediaSplitMutation();

  const handleSetMetadata = useCallback(
    (metadata: Metadata) => async (value: string[] | number[]) => {
      if (!project) return;
      let valueReformatted: MetadataValueType;
      if (metadata.type === MetadataType.Boolean) {
        valueReformatted = value?.[0] === t('true');
      } else if (!metadata.allowMultiple) {
        valueReformatted = metadata.type === MetadataType.Number ? Number(value?.[0]) : value?.[0];
      } else {
        valueReformatted =
          metadata.type === MetadataType.Number
            ? (value as string[]).map(val => Number(val))
            : value;
      }

      try {
        await attachMetadataToMediasApi(
          project,
          selectedMediaOptions,
          {
            [metadata.id]: valueReformatted,
          },
          currentModelId!,
        );
        enqueueSnackbar(
          t('{{temp0}}:{{temp1}} successfully set for {{temp2}} media ', {
            temp0: metadata.name,

            temp1: JSON.stringify(valueReformatted),

            temp2: selectedMediaCount,
          }),
          {
            variant: 'success',
          },
        );
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [currentModelId, enqueueSnackbar, project, selectedMediaCount, selectedMediaOptions],
  );

  const handleSplitSet = useCallback(
    (projectSplitId: number) => {
      if (!project?.datasetId) return;
      updateMediaSplit.mutate({
        datasetId: project.datasetId,
        splitSet: projectSplitId,
        selectMediaOptions: selectedMediaOptions,
      });
    },
    [project?.datasetId, selectedMediaOptions],
  );

  // these flags will be changed to project settings in the future
  const enableLabelingTask = project?.labelType !== LabelType.AnomalyDetection;
  const enableSnapshot = project?.labelType !== LabelType.SegmentationInstantLearning;

  const canUploadData = useHasPermission(UserPermission.UploadData);
  const { data: projectSplits = [] } = useGetProjectSplitQuery();

  const metadataTooltip = useMemo(() => {
    if (!metadataList.length) {
      return (
        <span>
          {t('No metadata is set up, to create new one, {{link}}.', {
            link: (
              <Link className={styles.setMetadataBtn} to={CLEF_PATH.data.metadata}>
                {t('click here')}
              </Link>
            ),
          })}
        </span>
      );
    }
    return '';
  }, [metadataList.length, styles.setMetadataBtn]);

  const renderCreateLabelingTaskButton = (
    <Button
      className={styles.moreSubButton}
      onClick={() => {
        setShowLabelingTaskDialog(true);
      }}
      id="data-browser-create-task-btn"
    >
      {t('Create labeling task')}
    </Button>
  );

  const setSplitButton = useMemo(() => {
    if (projectSplits.length > 0) {
      return (
        <Dropdown
          extraGutter={{ horizontal: 0, vertical: 8 }}
          placement="left"
          dropdown={toggleDropdown => (
            <SetSplitDropdown
              toggleDropdown={toggleDropdown}
              setSplit={handleSplitSet}
              enableLinearProgress
              onSetSplitSuccess={() => {
                dispatch(draft => {
                  draft.selectingMediaMode = 'select';
                  draft.selectedOrUnselectedMedia = [];
                });
              }}
            />
          )}
          classes={{
            root: styles.setMetadataContainer,
          }}
        >
          <Button className={styles.moreSubButton} id="data-browser-set-split-btn">
            {t('Set split')}
          </Button>
        </Dropdown>
      );
    }
    return null;
  }, [projectSplits.length, styles.setMetadataContainer, styles.moreSubButton, handleSplitSet]);

  const addTagBtn = useMemo(() => {
    return (
      <Dropdown
        extraGutter={{ horizontal: 0, vertical: 8 }}
        placement={state.rightSidebar === 'model_performance' ? 'left' : 'right'}
        dropdown={toggleDropdown => (
          <AddTagDropdown
            selectedMediaOptions={selectedMediaOptions}
            closeDropdown={() => toggleDropdown(false)}
          />
        )}
        classes={{
          root: styles.setMetadataContainer,
        }}
      >
        <Button
          variant="text"
          className={classNames(styles.moreSubButton)}
          id="data-browser-add-tags-btn"
        >
          {t('Add tags')}
        </Button>
      </Dropdown>
    );
  }, [selectedMediaOptions, state.rightSidebar]);

  const SetMetadataBtn = useMemo(() => {
    return (
      <Tooltip
        title={metadataTooltip}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          e.nativeEvent.stopImmediatePropagation();
        }}
        interactive
        arrow
        placement="left"
      >
        <Button
          variant="text"
          className={classNames(styles.moreSubButton, {
            [styles.disabled]: !!metadataTooltip,
          })}
          id="data-browser-set-metadata-btn"
          onClick={e => {
            if (metadataTooltip) {
              e.stopPropagation();
            }
          }}
        >
          {t('Set metadata')}
        </Button>
      </Tooltip>
    );
  }, [metadataTooltip, styles.disabled, styles.moreSubButton]);

  const [, setCreateSnapshotDialogMode] = useAtom(createSnapshotDialogModeAtom);

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        style={style}
        className={styles.container}
      >
        <Grid item container direction="row" alignItems="center" wrap="nowrap">
          <Typography
            variant="body1"
            className={styles.mediaCountText}
            data-testid="databrowser-selected-medias"
          >
            {t('{{mediaCount}} image(s) selected', {
              mediaCount: <strong>{selectedMediaCount}</strong>,
            })}
          </Typography>
          <Button
            color="secondary"
            className={styles.textButton}
            onClick={() =>
              // switch excluding to selecting, reset exclude list
              dispatch(draft => {
                draft.selectingMediaMode = 'select';
                draft.selectedOrUnselectedMedia = [];
              })
            }
            id="clear-selected-btn"
          >
            {t('Deselect all')}
          </Button>
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          wrap="nowrap"
          justifyContent="flex-end"
        >
          <PopupState variant="popover" popupId="more-btn-popup-popover">
            {popupState => (
              <div ref={optionsBtnRef} className={styles.optionsBtnBox}>
                <Button
                  id="more-options-btn"
                  variant="outlined"
                  className={styles.textButton}
                  endIcon={<ArrowDropDown />}
                  {...bindTrigger(popupState)}
                >
                  {t('Options')}
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  disableEnforceFocus
                  className={styles.mediaOptionsPopover}
                  anchorEl={() => optionsBtnRef.current!}
                  container={() => optionsBtnRef.current!}
                >
                  <Box
                    display="flex"
                    paddingTop={2}
                    paddingBottom={2}
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    {/* TODO: will add this back once we have new batch set class API */}
                    {/* {enableSetClass && <SetClassMenu />} */}
                    {!onlyRemove && (
                      <>
                        {enableLabelingTask && renderCreateLabelingTaskButton}
                        {setSplitButton}

                        {metadataTooltip ? (
                          SetMetadataBtn
                        ) : (
                          <Dropdown
                            extraGutter={{ horizontal: 0, vertical: 8 }}
                            placement="left"
                            dropdown={toggleDropdown => (
                              <SetMetadataDropdown
                                setMetadataAction={(metadata: Metadata) =>
                                  async (value: string[] | number[]) => {
                                    await handleSetMetadata(metadata)(value);
                                    toggleDropdown(false);
                                    dispatch(draft => {
                                      draft.selectingMediaMode = 'select';
                                      draft.selectedOrUnselectedMedia = [];
                                    });
                                  }}
                              />
                            )}
                            classes={{
                              root: styles.setMetadataContainer,
                            }}
                          >
                            {SetMetadataBtn}
                          </Dropdown>
                        )}

                        {addTagBtn}
                      </>
                    )}
                    {enableSnapshot && (
                      <div className={styles.snapshotBtnWrapper}>
                        <Button
                          variant="text"
                          className={styles.moreSubButton}
                          onClick={() =>
                            setCreateSnapshotDialogMode(CreateSnapshotDialogMode.SUBSET)
                          }
                          id="data-browser-create-dataset-btn"
                        >
                          {t('Save snapshot')}
                        </Button>
                      </div>
                    )}
                    {canUploadData && (
                      <Button
                        variant="text"
                        color="secondary"
                        className={styles.moreSubButton}
                        onClick={() => setShowRemoveMediaDialog(true)}
                        id="data-browser-remove-btn"
                      >
                        {t('Remove from dataset')}
                      </Button>
                    )}
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </Grid>
      </Grid>
      {/* Labeling task creation Dialog */}
      {showLabelingTaskDialog && (
        <LabelingTaskCreationDialog
          onTaskCreationSuccess={() => {
            dispatch(draft => {
              draft.selectingMediaMode = 'select';
              draft.selectedOrUnselectedMedia = [];
            });
          }}
          selectedMediaOptions={selectedMediaOptions}
          onClose={() => setShowLabelingTaskDialog(false)}
        />
      )}
      {/* Remove Media Dialog */}
      {showRemoveMediaDialog && (
        <RemoveMediaDialog onClose={() => setShowRemoveMediaDialog(false)} />
      )}
    </>
  );
};

export default MediaSelectionActions;
